<template>
<div>
  <h1>page not found</h1>
</div>
</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style scoped>

</style>